import {
  RESET_TRANSACTION,
  FETCH_TRANSACTION,
} from "../../constants/action.constants";

const initialState = {
  data: {},
};

const transactionViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION:
      return { data: action.payload };
    case RESET_TRANSACTION:
      return initialState;
    default:
      return state;
  }
};

export default transactionViewReducer;
