import {
  FETCH_TOUCHPOINT_GROUP,
  RESET_TOUCHPOINT_GROUP,
} from "../../constants/action.constants";

const initialState = {
  data: {},
};

const touchpointGroupViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOUCHPOINT_GROUP:
      return { data: action.payload };
    case RESET_TOUCHPOINT_GROUP:
      return initialState;
    default:
      return state;
  }
};

export default touchpointGroupViewReducer;
