import { getFullRouteWithQueryString } from "../services/commonMethods";

//Routes of the application.
export const APP_ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  ORDER_VIEW: "/order/:id",
  NOTIFICATIONS: "/notifications",

  CHANNEL_LIST: "/channel-list",
  TOUCH_POINT_LIST: "/touchpoint-list",
  SYNC_TOUCH_POINT: "/sync-touchpoint",
  TOUCH_POINT_VIEW: "/touchpoint/:id",
  TOUCHPOINT_GROUP_LIST: "/touchpoint-groups-list",
  TOUCHPOINT_GROUP_CREATE: "/add-touchpoint-group",
  TOUCHPOINT_GROUP_EDIT: "/add-touchpoint-group/:id",
  TOUCHPOINT_GROUP_VIEW: "/touchpoint-group-view/:id",
  CUSTOMER_LIST: "/customer-list",
  CUSTOMER_CREATE: "/add-customer",
  CUSTOMER_VIEW: "/customer-view/:id",
  CUSTOMER_EDIT: "/add-customer/:id",
  GROUP_LIST: "/group-list",
  GROUP_VIEW: "/group-view/:id",
  WALLET_LIST: "/wallet-list",
  WALLET_CREATE: "/add-wallet",
  WALLET_EDIT: "/add-wallet/:id",
  WALLET_VIEW: "/wallet/:id",
  INSTITUTION_LIST: "/instituition-list",
  INSTITUTION_VIEW: "/instituition-view/:id",
  RULE_LIST: "/rules",
  RULE_CREATE: "/add-rule",
  RULE_EDIT: "/edit-rule/:id",
  RULE_VIEW: "/rule/:id",
  TRANSACTION_LIST: "/transaction-list",
  TRANSACTION_VIEW: "/transaction/:id",
};

//Routes with inline query value. Example : zone/15 , warehouse/15
export const ROUTES_WITH_INLINE_PARAMS = {
  VIEW_CUSTOMER: (id) => APP_ROUTES.CUSTOMER_VIEW.replace(":id", id),
  VIEW_TOUCH_POINT: (id) => APP_ROUTES.TOUCH_POINT_VIEW.replace(":id", id),
  VIEW_GROUP: (id) => APP_ROUTES.GROUP_VIEW.replace(":id", id),
  EDIT_CUSTOMER: (id) => APP_ROUTES.CUSTOMER_EDIT.replace(":id", id),
  VIEW_RULE: (id) => APP_ROUTES.RULE_VIEW.replace(":id", id),
  EDIT_RULE: (id) => APP_ROUTES.RULE_EDIT.replace(":id", id),

  VIEW_INSTITUTION: (id) => APP_ROUTES.INSTITUTION_VIEW.replace(":id", id),
  VIEW_TOUCHPOINT_GROUP: (id) =>
    APP_ROUTES.TOUCHPOINT_GROUP_VIEW.replace(":id", id),
  EDIT_TOUCHPOINT_GROUP: (id) =>
    APP_ROUTES.TOUCHPOINT_GROUP_EDIT.replace(":id", id),
};
