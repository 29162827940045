import {
  FETCH_VENDING_MACHINE,
  RESET_VENDING_MACHINE,
} from "../../constants/action.constants";

const initialState = {
  data: {},
};

//Controller for the storage.
const vmReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDING_MACHINE:
      return { data: action.payload };
    case RESET_VENDING_MACHINE:
      return initialState;
    default:
      return state;
  }
};

export default vmReducer;
