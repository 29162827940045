import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
} from "@material-ui/core";
import { withRouter, useLocation } from "react-router-dom";
import ROUTES from "../../../routeManagement/routes";
import { getActiveRoute } from "../../../constants/mapping";
import DownArrow from "../../../assets/icons/arrow.svg";
import ActiveDownArrow from "../../../assets/icons/arrow_orange.svg";
import { checkRoute } from "../../../services/routesModify";
import { useDispatch, useSelector } from "react-redux";
import { checkShowModal } from "../../../modules/actions/cancelConfirm.action";
import { Fragment } from "react";
import { CancelApiSourcesContext } from "../../../gatekeeper";
import { BootstrapTooltip } from "../ActionColumn";

function SideNavBar(props) {
  const [routes, setRoutes] = useState([]);
  const dispatchActions = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state.cancelConfirmReducer);
  // const collapseSidebar = useSelector(
  //   (state) => state.collapseSidebarReducer.collapseSidebar
  // );
  const me = useSelector((state) => state.meReducer.data);
  //Function to cancel all ongoing api requests.
  const cancelApiRequests = useContext(CancelApiSourcesContext);

  //On change of route, cancel ongoing api requests.
  useEffect(() => {
    //Cancel api requests only if user data is there.
    //It prevents me api from getting cancelled.
    if (me.id) {
      cancelApiRequests();
    }
  }, [location]);

  useEffect(() => {
    let temp = ROUTES.filter((route) => {
      return route.sidebar && checkRoute(route);
    });
    setRoutes(temp);
  }, []);

  const handleClick = (route) => {
    if (state.formDirtBool) {
      dispatchActions(checkShowModal(() => props.history.push(route)));
    } else {
      props.history.push(route);
    }
  };

  const formLink = (route, props, index) => {
    return (
      <div
        className={`sidenav-content ${
          getActiveRoute(route.title, props.location.pathname)
            ? "active-bar"
            : ""
        }`}
      >
        {
          // collapseSidebarbool ?
          <BootstrapTooltip title={route.title} placement="top">
            <div
              className="collapsed-sidebar"
              onClick={() => handleClick(route.route)}
            >
              <img
                src={route.icon}
                alt={route.title}
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
              <div style={{ fontSize: "0.6rem" }}>{route.title}</div>
            </div>
          </BootstrapTooltip>
          // : (
          //   <div
          //     className="open-sidebar"
          //     onClick={() => handleClick(route.route)}
          //   >
          //     <div className="img-box">
          //       <img
          //         src={route.icon}
          //         alt={route.title}
          //         style={{
          //           width: "70%",
          //           height: "70%",
          //         }}
          //       />
          //     </div>

          //     <span>{route.title}</span>
          //     {route.hasSubRoutes ? (
          //       <div className="arrow-icon">
          //         <img
          //           src={RightLessThanArrow}
          //           alt={RightLessThanArrow}
          //           style={{
          //             width: "13px",
          //             height: "13px",
          //           }}
          //         />
          //         &nbsp; &nbsp;
          //       </div>
          //     ) : (
          //       ""
          //     )}
          //   </div>
          // )
        }
        {route.hasSubRoutes ? (
          <div
            className={`sidenav-dropdown-content ${
              // collapseSidebarbool
              //   ?
              "collapse-left-position"
              // :
              //  "open-left-position"
            }`}
          >
            {route.subRoutes.map((subRoute, index) =>
              checkRoute(subRoute) ? (
                <div
                  className={`sidenav-dropdown-title ${
                    getActiveRoute(subRoute.title, props.location.pathname)
                      ? "active-sub-title"
                      : ""
                  }`}
                  key={index}
                  onClick={() => handleClick(subRoute.route)}
                >
                  {subRoute.title}
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : null}
      </div>
      // <Accordion
      //   key={index}
      //   classes={{
      //     root: "accordion-root-sidenavbar",
      //   }}
      // >
      //   <AccordionSummary
      //     expandIcon={
      //       route.hasSubRoutes ? (
      //         <img
      //           src={
      //             getActiveRoute(route.title, props.location.pathname)
      //               ? ActiveDownArrow
      //               : DownArrow
      //           }
      //           alt={`{route.title} Image`}
      //           className="float-right"
      //         />
      //       ) : (
      //         ""
      //       )
      //     }
      //     classes={{
      //       root: `${
      //         // collapseSidebar ? "side-navbar-link-collapse" : "side-navbar-link"
      //         "side-navbar-link-collapse"
      //       } ${
      //         getActiveRoute(route.title, props.location.pathname)
      //           ? "active-link"
      //           : "inactive-link"
      //       }`,
      //       expanded: "accordion-expanded-sidenavbar-link",
      //     }}
      //     onClick={(ev) => {
      //       //return if clicked on icon
      //       if (ev.target.tagName === "IMG") {
      //         return;
      //       }
      //       handleClick(route.route);
      //     }}
      //   >
      //     <div className="sidebar-anchor">
      //       <img
      //         src={
      //           getActiveRoute(route.title, props.location.pathname)
      //             ? route.activeIcon
      //             : route.icon
      //         }
      //         alt={route.title}
      //       />
      //       {
      //       // collapseSidebar ?
      //       (
      //         <Fragment>
      //           <label className="collapsible-navbar-title">
      //             {route.title}
      //           </label>
      //         </Fragment>
      //       )
      //       // : (
      //       //   <Fragment>
      //       //     <label className="sidebar-link--text ml-2">{route.title}</label>
      //       //   </Fragment>
      //       // )
      //       }
      //     </div>
      //   </AccordionSummary>
      //   {route.hasSubRoutes ? (
      //     <AccordionDetails>
      //       {route.subRoutes.map((subRoute, subIndex) => {
      //         return checkRoute(subRoute) ? (
      //           <div
      //             className={`${
      //               // collapseSidebar
      //               //   ?
      //                  "side-navbar-link-collapse"
      //                 // : "side-navbar-link"
      //             } ${
      //               getActiveRoute(subRoute.title, props.location.pathname)
      //                 ? "sub-active-link"
      //                 : "sub-inactive-link"
      //             }`}
      //             onClick={(ev) => handleClick(subRoute.route)}
      //             key={subIndex}
      //           >
      //             <img
      //               src={
      //                 getActiveRoute(subRoute.title, props.location.pathname)
      //                   ? subRoute.activeIcon
      //                   : subRoute.icon
      //               }
      //               alt={subRoute.title}
      //             />
      //             {
      //             // collapseSidebar ?
      //             (
      //               <Fragment>
      //                 &nbsp;&nbsp;&nbsp;
      //                 <label className="collapsible-navbar-title2">
      //                   {subRoute.title}
      //                 </label>
      //               </Fragment>
      //             )
      //             // : (
      //             //   <Fragment>
      //             //     &nbsp;&nbsp;&nbsp;
      //             //     <label className="sidebar-link--text">
      //             //       {subRoute.title}
      //             //     </label>
      //             //   </Fragment>
      //             // )
      //             }
      //           </div>
      //         ) : (
      //           ""
      //         );
      //       })}
      //     </AccordionDetails>
      //   ) : (
      //     ""
      //   )}
      // </Accordion>
    );
  };

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        anchor="left"
        classes={{
          root: "side-navbar-root",
          paperAnchorLeft:
            //  collapseSidebar?
            "collapse-side-navbar side-navbar ",
          // :
          //  "side-navbar",
          paper: "paper-overflow",
        }}
      >
        {routes.map((route, index) => formLink(route, props, index))}
      </Drawer>
    </React.Fragment>
  );
}

export default withRouter(SideNavBar);
