import axios, { CancelToken } from "axios";
import { ENV_CONFIG } from "../constants/index";

function getConfig(accessToken, multipart) {
  let source = CancelToken.source();
  let cancel, config;
  // Return the cancel function and add it to the header.
  // See: https://github.com/mzabriskie/axios#cancellation
  config = {
    cancelToken: source.token,
    source,
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": multipart ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return {
    cancel,
    config,
  };
}

export function getBaseURL(serviceToUse) {
  switch (serviceToUse) {
    case "customerMGMT":
      return ENV_CONFIG.customerMgmt_baseURL;
    case "transactionMGMT":
      return ENV_CONFIG.transactionMgmt_baseURL;
    default:
      return ENV_CONFIG.baseURL;
  }
}

export function webapiGet(accessToken, url, serviceToUse) {
  const config = getConfig(accessToken);
  let baseURL = getBaseURL(serviceToUse);
  return {
    request: axios
      .get(`${baseURL}${url}`, config.config)
      .then((response) => response)
      .catch((err) => err),
    cancel: config.cancel,
  };
}

export function webapiPut(accessToken, url, options, serviceToUse) {
  const config = getConfig(accessToken);
  let baseURL = getBaseURL(serviceToUse);
  return {
    request: axios
      .put(`${baseURL}${url}`, options, config.config)
      .then((response) => response)
      .catch((err) => err),
    cancel: config.cancel,
  };
}

export function webapiMultipartPut(accessToken, url, options) {
  const config = getConfig(accessToken, true);
  return {
    request: axios
      .put(`${ENV_CONFIG.baseURL}${url}`, options, config.config)
      .then((response) => response)
      .catch((err) => err),
    cancel: config.cancel,
  };
}

export function webapiPost(accessToken, url, options, serviceToUse) {
  const config = getConfig(accessToken);
  let baseURL = getBaseURL(serviceToUse);
  return {
    request: axios
      .post(`${baseURL}${url}`, options, config.config)
      .then((response) => response)
      .catch((err) => err),
    cancel: config.cancel,
  };
}

export function webapiMultipartPost(accessToken, url, options, serviceToUse) {
  const config = getConfig(accessToken, true);
  let baseURL = getBaseURL(serviceToUse);
  return {
    request: axios
      .post(`${baseURL}${url}`, options, config.config)
      .then((response) => response)
      .catch((err) => err),
    cancel: config.cancel,
  };
}

export function webapiDelete(accessToken, url, options, serviceToUse) {
  const config = getConfig(accessToken);
  let baseURL = getBaseURL(serviceToUse);
  if (options) config.config.data = options;
  return {
    request: axios
      .delete(`${baseURL}${url}`, config.config)
      .then((response) => response)
      .catch((err) => err),
    cancel: config.cancel,
  };
}

export function webapiAuthPost(url, options) {
  return {
    request: axios
      .post(`${ENV_CONFIG.baseURL}${url}`, options)
      .then((response) => response)
      .catch((err) => err),
  };
}
