import {
  FETCH_TRANSACTION_LIST,
  RESET_TRANSACTION_LIST,
} from "../../constants/action.constants";

//Storage for data.
const initialState = {
  list: [],
};

//Controller for the storage.
const transactionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_LIST:
      return { list: action.payload };
    case RESET_TRANSACTION_LIST:
      return initialState;
    default:
      return state;
  }
};

export default transactionListReducer;
