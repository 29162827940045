import { FETCH_WALLET, RESET_WALLET } from "../../constants/action.constants";

const initialState = {
  data: {},
};

const walletViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLET:
      return { data: action.payload };
    case RESET_WALLET:
      return initialState;
    default:
      return state;
  }
};

export default walletViewReducer;
