import {
  FETCH_RULE_LIST,
  RESET_RULE_LIST,
} from "../../constants/action.constants";

const initialState = {
  list: [],
};

//Controller for the storage.
const ruleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RULE_LIST:
      return { list: action.payload };
    case RESET_RULE_LIST:
      return initialState;
    default:
      return state;
  }
};

export default ruleListReducer;
