import React from "react";
import StarFilled from "../assets/icons/starFilled.svg";
import StarEmpty from "../assets/icons/StarEmpty.svg";
import { Fragment } from "react";
import {
  CURRENCIES,
  SYMBOLS,
  DEFAULT_VALUES,
  ENUMS,
  KEY_CODES,
  USER_ROLES,
} from "../constants";
import APP_STRINGS from "../languages/EN.json";
import { CircularProgress } from "@material-ui/core";
import ErrorBoundary from "../pages/components/ErrorBoundary/ErrorBoundary";

export const webApiCheck = (response) => {
  return (
    response &&
    (response.status === 200 ||
      response.status === 201 ||
      response.status === 204)
  );
};
export const amount_Type = (rs_Amount, payment_type) => {
  return payment_type === "PAYMENT" || payment_type === "MANUAL_DEDUCTION"
    ? `-${rs_Amount}`
    : payment_type === "RECHARGE_FAILED"
    ? rs_Amount
    : `+${rs_Amount}`;
};
export const objectHasKeysCheck = (obj) => {
  return obj && obj.constructor === Object && Object.keys(obj).length > 0;
};

export const getDateAndTime = (epoc) => {
  if (!epoc) return;
  const date = new Date(epoc).toString().split(" ");
  const time = date[4].split(":");
  let format = "AM";

  if (parseInt(time[0]) >= 12) {
    format = "PM";
    if (parseInt(time[0]) !== 12) {
      time[0] = time[0] - 12;
    }
  }

  return `${date[1]} ${date[2]} ${date[3]} ${time[0]}:${time[1]} ${format}`;
};

export const getTimeStamp = (time) => {
  if (!time) return null;

  return new Date(time).getTime();
};

export function convertImageToBase64(url, callback, outputFormat) {
  var canvas = document.createElement("CANVAS");
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL(outputFormat || "image/png");
    callback.call(this, dataURL);
    canvas = null;
  };
  img.src = url;
}

export const getImageSizeFromBase64 = (base64) => {
  const type = getImageType(base64);
  let stringLength = base64.length - `data:image/${type};base64,`.length;
  let sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
  let sizeInMb = sizeInBytes / (1024 * 1024);
  return sizeInMb;
};

export const getImageType = (base64) => {
  const split = base64.split("/");
  const type = split[1].split(";")[0];
  return type;
};

export const getStatusColor = (status) => {
  switch (status) {
    case 0:
      return {
        status: "Pending",
        color: "yellow",
      };
    case 2:
      return {
        status: "Failure",
        color: "red",
      };
    case 1:
      return {
        status: "Success",
        color: "green",
      };
    default:
      return {};
  }
};

export const generateAlphabetArray = (charA, charZ) => {
  let a = [],
    i = charA.charCodeAt(0),
    j = charZ.charCodeAt(0);
  for (; i <= j; ++i) {
    a.push(String.fromCharCode(i));
  }
  return a;
};

export const ALPHABET_ARR = generateAlphabetArray("A", "Z");

//  For Star rating, totalStars is use for how many stars to be shown
// export const formRating = (rating, totalStars = DEFAULT_VALUES.STAR_RATING) => {
//   let arr = [];
//   // for loop is use for dynamic count of star
//   for (let i = 1; i <= totalStars; i++) {
//     arr.push(i);
//   }
//   return (
//     <Fragment>
//       {arr.map((ele) => (
//         <img
//           src={ele <= rating ? StarFilled : StarEmpty}
//           alt="star"
//           className="vm-rating"
//         />
//       ))}
//     </Fragment>
//   );
// };

export const getLabel = (row_number, col_number, type) => {
  switch (type) {
    case "alpha-alpha":
      return `${ALPHABET_ARR[row_number]}-${ALPHABET_ARR[col_number]}`;
    case "alpha-num":
      return `${ALPHABET_ARR[row_number]}-${col_number + 1}`;
    case "num-num":
      return `${row_number + 1}-${col_number + 1}`;
    case "num-alpha":
      return `${row_number + 1}-${ALPHABET_ARR[col_number]}`;
    case "zero-num":
      return `${row_number}-${col_number + 1}`;
    case "one-num":
      return `${row_number + 1}-${col_number}`;
    case "hundred-ten-num":
      return `1${row_number + 1}-${col_number}`;
    default:
      return;
  }
};

export const radioData = [
  {
    value: "alpha-num",
    title: "Alpha-Num ( A1, A2... )",
  },
  {
    value: "alpha-alpha",
    title: "Alpha-Alpha ( AA, AB...)",
  },
  {
    value: "num-num",
    title: "Num-Num ( 11, 12... )",
  },
  {
    value: "num-alpha",
    title: "Num-Alpha ( 1A, 1B... )",
  },
  {
    value: "zero-num", // as the counting starts form 0
    title: "Num-Num ( 01, 02... )",
  },
  {
    value: "one-num", // as the counting starts from 1
    title: "Num-Num ( 10, 11... )",
  },
  {
    value: "hundred-ten-num", // as the counting starts from 110
    title: "Num-Num ( 110, 111... )",
  },
];

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const checkExpiryDate = (startDate, endDate, type) => {
  let msg = "";
  startDate = new Date(startDate).getTime();
  endDate = new Date(endDate).getTime();
  let presentEpoc = new Date().getTime();
  if (presentEpoc > startDate) {
    let timeDiff = endDate - presentEpoc;
    switch (true) {
      case timeDiff <= 1000:
        msg = `The ${type} has expired`;
        break;
      case timeDiff > 1000 && timeDiff <= 60000:
        msg = `The ${type} will expire in ${parseInt(
          timeDiff / 1000
        )} seconds.`;
        break;
      case timeDiff > 60000 && timeDiff <= 60 * 60000:
        msg = `The ${type} will expire ${parseInt(timeDiff / 60000)} minutes.`;
        break;
      case timeDiff > 60 * 60000 && timeDiff <= 24 * 60 * 60000:
        msg = `The ${type} will expire ${timeDiff / (60 * 60000)} hours.`;
        break;
      case timeDiff > 24 * 60 * 60000 && timeDiff <= 4 * 24 * 60 * 60000:
        msg = `The ${type} will expire ${parseInt(
          timeDiff / (24 * 60 * 60000)
        )} days.`;
        break;
      default:
        msg = "";
        break;
    }
  }
  return msg;
};

//Delete extra fields from json
export const jsonCleanup = (obj, keys, keysAreExceptions = true) => {
  Object.keys(obj).forEach((key) => {
    if (
      keysAreExceptions ? keys.indexOf(key) === -1 : keys.indexOf(key) !== -1
    ) {
      delete obj[key];
    }
  });
  return obj;
};
//Checks if user has permission for something.
export const checkUserHasPermission = (role, permissions) => {
  let flag = false;
  if (role) {
    role.permissions.forEach((per) => {
      if (permissions.indexOf(per) > -1) {
        flag = true;
      }
    });
  }
  return flag;
};

//Roles list according to user role and permissions. Only for add user screen.
export const getAllowedRolesList = (
  roleCode,
  list,
  userHasPermissionToAddFranchise
) => {
  switch (roleCode) {
    case USER_ROLES.COMPANY_ADMIN:
      return userHasPermissionToAddFranchise
        ? list
        : list.filter((ele) => ele.role_code !== USER_ROLES.FRANCHISE_ADMIN);
    case USER_ROLES.FRANCHISE_ADMIN:
      return list.filter((ele) => ele.role_code === USER_ROLES.FRANCHISE_ADMIN);
    default:
      return [];
  }
};

export const handleNumberKeyPress = (e) => {
  var key = !isNaN(e.charCode) ? e.charCode : e.keyCode;
  function keyAllowed() {
    var keys = [
      8, 9, 13, 16, 17, 18, 19, 20, 27, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 91, 92, 93,
    ];
    if (key && keys.indexOf(key) === -1) return false;
    else return true;
  }
  if (!keyAllowed()) e.preventDefault();
};

//Submits a form safely when user hits enter
export const safelySubmitFormOnKeyPress = () => {
  document.addEventListener("keypress", (ev) => {
    const { target } = ev;
    //If user hits enter key or numpad key
    if ([KEY_CODES.ENTER_KEY, KEY_CODES.NUMPAD_ENTER_KEY].includes(ev.code)) {
      if (target.type === "search") {
        ev.preventDefault();
      } else {
        let formButton = document.querySelectorAll("form button[type=submit]");
        const lastIndex = formButton.length - 1;
        //check if enter is being hit inside a form and there is a submit button there.
        if (lastIndex >= 0) {
          ev.preventDefault();
          return formButton[lastIndex].click();
        }
      }
    }
  });
};

export const disableNumberInputScrollChange = function () {
  //Disables scroll and change number facility in number input.
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
};

//calculate percentage of a number.
export const calculatePercentage = (number, percentage) => {
  percentage = parseInt(percentage);
  number = parseInt(number);
  if (!percentage) {
    return number;
  }

  return parseInt(number * (percentage / 100));
};

//prevent any function default behaviour.
export const preventDefaultBehaviour = (e) => {
  e.preventDefault();
};

//delete fields that do not have data.
export const removeNullFieldsFromJson = (payload, exceptions = []) => {
  Object.keys(payload).forEach((key) => {
    if (!payload[key] && exceptions.indexOf(key) === -1) {
      delete payload[key];
    }
  });
  return payload;
};

//returns an url with query parameters.
export const getFullRouteWithQueryString = (route, queryParams) => {
  //Remove keys that has no value
  removeNullFieldsFromJson(queryParams);
  let queryString = "";

  //Get whole queryString.
  for (const [key, value] of Object.entries(queryParams)) {
    queryString += !queryString ? `?${key}=${value}` : `&${key}=${value}`;
  }

  return route + queryString;
};

//Checks if company has rights to directly transfer products directly from store to vending machine
export const checkStoreToVMapplicable = (props) =>
  props.me.Role.role_code === USER_ROLES.FRANCHISE_ADMIN &&
  props.me.Franchises[0].Franchise.type ===
    ENUMS.FRANCHISE_TYPE.INDEPENDENT.type;

//Handle change for all input changes.
export const setCustomInputHandler = function (ev, extraData = {}) {
  let {
    target: { name, value, type },
  } = ev;

  //If type of input is number, return value after number coercion.
  if (type === "number" && value !== "") {
    value = Number(value);
  }

  return this.setState({ ...extraData, [name]: value });
};

export const getJsonInInputEventFormat = function (name, value) {
  return { target: { name, value } };
};

//returns string with highlighted text
export const getHighlightedText = function (
  text,
  highlightTexts,
  className = "highlighted-text__yellow"
) {
  if (typeof highlightTexts === "string") {
    //Prevents * error of regular expressions.
    text = text
      .toLowerCase()
      .replace(
        highlightTexts.toLowerCase(),
        `<span class=${className}>${highlightTexts}</span>`
      );
  } else if (highlightTexts.length) {
    highlightTexts.forEach((element) => {
      //Case insensitive replacement
      var replacePattern = new RegExp(element, "gi");

      text = text.replace(
        replacePattern,
        `<span class=${className}>${element}</span>`
      );
    });
  }
  return text;
};

export const renderPriceWithSymbol = function (price) {
  if (price) {
    return `${CURRENCIES.RUPEES} ${price}`;
  } else {
    return DEFAULT_VALUES.DOUBLE_DASH;
  }
};
export const renderPercentageWithSymbol = function (number) {
  return `${number} ${SYMBOLS.PERCENTAGE}`;
};
export const renderNetWeightWithSymbol = function (weight, symbol) {
  return `${weight}${symbol.toLowerCase()}`;
};
//Returns object with a component texts to be shown.
export const getComponentTexts = function (location) {
  const splittedLocations = location.split(".");
  let answer = APP_STRINGS[splittedLocations[0]];

  for (let i = 1; i < splittedLocations.length; i++) {
    answer = answer[splittedLocations[i]];
  }

  return answer;
};

//Check if all conditions are satisfied of the functions.
export const checkIfAllConditionsSatisfy = function (...functions) {
  return functions.every((conditionalFunction) => conditionalFunction());
};

// Retreive refund status name
export const getRefundStatusText = function (refundStatus) {
  const vendStatusMsg = getComponentTexts("refund_status_text");
  switch (refundStatus) {
    case 1:
      return vendStatusMsg.success;
  }
};

//Retreive refund status color.
export const getRefundStatusTextColor = function (vendStatus) {
  switch (vendStatus) {
    case 1:
      return "#3b953d";
    case 2:
      return "#ffc41e";
    case 3:
      return "#fc8b55";
    case 4:
      return "#1b66d7";
    case 5:
      return "#ff0000";
    default:
      return "#000";
  }
};

//Retreive Customer payment status color.
export const getCustomerStatusTypeTextColor = function (type) {
  switch (type) {
    case "AUTO_REFUND":
      return "#00FF00";
    case "RECHARGE":
      return "#00FF00";
    case "RECHARGE_SUCCESS":
      return "#00FF00";
    case "RECHARGE_FAILED":
      return "#F95A3F";
    case "MANUAL_REFUND":
      return "#00FF00";
    case "PAYMENT":
      return "#F95A3F";
    case "MANUAL_DEDUCTION":
      return "#F95A3F";
    default:
      return "#000";
  }
};

// function to check user window size
export const checkMaxWindowSize = function (maxWindowSize) {
  return window.matchMedia(`(max-width:${maxWindowSize}px)`).matches;
};

//Returns an unique custom input name which can be used for dynamic validation.
//Example: min_quantity_12,min_quantity_23
export const getUniqueDynamicInputName = function (element, suffixKey) {
  return function (prefix) {
    return `${prefix}_${element[suffixKey]}`;
  };
};

//Do not forget to bind this to this function.
export const enableFormDirtiness = function (callback) {
  if (!this.props.formDirtBool) {
    this.props.setFormDirtiness();
    this.btns[1].btnProps.method = () =>
      this.props.checkShowModal(
        callback ? callback : () => this.props.history.goBack()
      );
  }
};

//Returns current page number by offset.
export const calculatePageNumberFromOffset = function (params) {
  const { offset, limit } = params;
  try {
    return offset / limit + 1;
  } catch {
    return 1;
  }
};

export const stringToNumberArrayConversion = function (str, separator = ",") {
  return str.length === 0
    ? []
    : str.split(separator).map((ele) => parseInt(ele));
};

export const ellipsisString = function (value, wordsLimit) {
  return value.length > 8 ? value.substring(0, wordsLimit) + "..." : value;
};

export function RenderLazyComponent(Component) {
  return function (props) {
    return (
      <React.Suspense
        fallback={
          <CircularProgress
            classes={{
              root: "circular-loader",
              colorPrimary: "circular-loader-color",
            }}
          />
        }
      >
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      </React.Suspense>
    );
  };
}

export function hasElementInViewPort(domElement) {
  if (!domElement) {
    return false;
  }
  //Returns element geometry in respect to screen.
  const elementGeometry = domElement.getBoundingClientRect();
  const { innerWidth, innerHeight } = window;

  return (
    elementGeometry.left >= 0 &&
    elementGeometry.top >= 0 &&
    elementGeometry.right <= innerWidth &&
    elementGeometry.bottom <= innerHeight
  );
}

export const getDateAndTimeWithSec = (epoc) => {
  if (!epoc) return;
  const date = new Date(epoc).toString().split(" ");

  const time = date[4].split(":");

  let format = "AM";

  if (parseInt(time[0]) >= 12) {
    format = "PM";
    if (parseInt(time[0]) !== 12) {
      time[0] = time[0] - 12;
    }
  }

  return `${date[1]} ${date[2]} ${date[3]} (${time[0]}:${time[1]}:${time[2]} ${format})`;
};

export const getPaymentStatusText = function (paymentStatus) {
  switch (paymentStatus) {
    case 1:
      return "Success";
    case 2:
      return "Failed";
    default:
      return "--";
  }
};

// get payment status color
export const getPaymentStatusColor = function (paymentStatus) {
  switch (paymentStatus) {
    case 1:
      return "green";
    case 2:
      return "red";
    default:
      return "grey";
  }
};

export const getVendingMachineStatusText = function (vendStatus) {
  const vendStatusMsg =
    getComponentTexts("common_methods").get_vending_machine_status_text;
  switch (vendStatus) {
    case 1:
      return vendStatusMsg.vend_success;
    case 2:
      return vendStatusMsg.partial_vend;
    case 3:
      return vendStatusMsg.vend_failure;
    default:
      return "--";
  }
};

//Retreive purchase order vend status color.
export const getVendingMachineStatusTextColor = function (vendStatus) {
  switch (vendStatus) {
    case 1:
      return "#3b953d";
    case 2:
      return "#ffc41e";
    case 3:
      return "#fc8b55";
    case 4:
      return "#1b66d7";
    case 5:
      return "#ff0000";
    default:
      return "#000";
  }
};
