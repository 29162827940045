// Dashboard
import DashboardIcon from "../assets/icons/dashboard.svg";
import InstanceIcon from "../assets/icons/instance_icon.svg";
import TouchPointIcon from "../assets/icons/touch_point_icon.svg";
import DashboardActiveIcon from "../assets/icons/dashboard_orange.svg";
import CustomerMgmtIcon from "../assets/icons/customer_mgmt.svg";
import RulesMgmtIcon from "../assets/icons/sidebar_rules.svg";
import transactionMgmtIcon from "../assets/icons/sidebar_transaction.svg";
import InstitutionMgmtIcon from "../assets/icons/sidebar_institution.svg";

// Permissions
import { PERMISSIONS } from "../constants/index";

import PurchaseOrderManagementIcon from "../assets/icons/order_managemnt.svg";
import PurchaseOrderManagementActiveIcon from "../assets/icons/order_managemnt_orange.svg";
import { APP_ROUTES } from "../constants/appRoutes";
//Supplier Management

import React from "react";
const AddInstitution = React.lazy(() =>
  import("../pages/containers/InstitutionMGMT/AddInstitution")
);
const InstitutionView = React.lazy(() =>
  import("../pages/containers/InstitutionMGMT/InstitutionView")
);
const InstitutionList = React.lazy(() =>
  import("../pages/containers/InstitutionMGMT/InstitutionList")
);
// const Dashboard = React.lazy(() => import("../pages/containers/Dashboard"));
const InstanceList = React.lazy(() =>
  import("../pages/containers/InstanceMGMT/InstanceList")
);
const TouchPointList = React.lazy(() =>
  import("../pages/containers/TouchPt_MGMT/TouchPt_List")
);
const TouchPointView = React.lazy(() =>
  import("../pages/containers/TouchPt_MGMT/TouchPt_View")
);
const TouchPointGroupsList = React.lazy(() =>
  import("../pages/containers/TouchPt_MGMT/TouchPt_Groups/TouchPt_Groups_List")
);
const AddTouchPtGroup = React.lazy(() =>
  import("../pages/containers/TouchPt_MGMT/TouchPt_Groups/AddTouchPtGroup")
);
const TouchPtGroupView = React.lazy(() =>
  import("../pages/containers/TouchPt_MGMT/TouchPt_Groups/TouchPt_GroupView")
);
const GroupList = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/Groups/GroupList")
);
const GroupView = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/Groups/GroupView")
);
const AddGroup = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/Groups/AddGroup")
);
const SyncTouchPoint = React.lazy(() =>
  import("../pages/containers/TouchPt_MGMT/Sync_TouchPoint/SyncTouchPt")
);
const CustomersList = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/CustomersList")
);
const CustomersView = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/CustomerView")
);
const AddCustomers = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/AddCustomer")
);
const WalletList = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/Wallet/WalletList")
);
const AddWallet = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/Wallet/AddWallet")
);
const WalletView = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/Wallet/WalletView")
);
const RuleList = React.lazy(() =>
  import("../pages/containers/RuleMGMT/RuleList")
);

const AddRule = React.lazy(() =>
  import("../pages/containers/RuleMGMT/AddRule")
);

const ViewRule = React.lazy(() =>
  import("../pages/containers/RuleMGMT/RuleOverview")
);
const TransactionList = React.lazy(() =>
  import("../pages/containers/TransactionMGMT/TransactionList")
);
const TransactionView = React.lazy(() =>
  import("../pages/containers/TransactionMGMT/TransactionView")
);

const ROUTES = [
  {
    title: "Channels",
    icon: InstanceIcon,
    activeIcon: InstanceIcon,
    component: InstanceList,
    route: "/channel-list",
    sidebar: true,
    permissions: [],
  },
  {
    title: "Touch Point",
    icon: TouchPointIcon,
    activeIcon: TouchPointIcon,
    component: TouchPointList,
    route: "/touchpoint-list",
    sidebar: true,
    hasSubRoutes: true,
    permissions: [],
  },
  {
    title: "Touchpoint List",
    component: TouchPointList,
    route: "/touchpoint-list",
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Touchpoint Groups",
    component: TouchPointGroupsList,
    route: "/touchpoint-groups-list",
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Touchpoint Groups",
    component: AddTouchPtGroup,
    route: "/add-touchpoint-group",
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Touchpoint Groups",
    component: AddTouchPtGroup,
    route: "/add-touchpoint-group/:id",
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Touchpoint Groups",
    component: TouchPtGroupView,
    route: APP_ROUTES.TOUCHPOINT_GROUP_VIEW,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Touch Point",
    component: SyncTouchPoint,
    route: "/sync-touchpoint",
    sidebar: false,
    permissions: [],
  },

  {
    title: "Touch Point",
    component: TouchPointView,
    route: APP_ROUTES.TOUCH_POINT_VIEW,
    sidebar: false,
    permissions: [],
  },

  {
    title: "Institution",
    icon: InstitutionMgmtIcon,
    activeIcon: InstitutionMgmtIcon,
    component: InstitutionList,
    route: "/instituition-list",
    sidebar: true,
    // hasSubRoutes: true,
    permissions: [],
  },

  {
    title: "Institution",
    component: InstitutionList,
    route: "/instituition-list",
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Institution",
    component: InstitutionView,
    route: "/instituition-view/:id",
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Institution",
    component: AddInstitution,
    route: "/add-instituition",
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Institution",
    component: AddInstitution,
    route: "/add-instituition/:id",
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },

  // {
  //   title: "Customer Overview",
  //   component: TransactionEmailReport,
  //   route: "/customer/:id",
  //   sidebar: false,
  // },
  {
    title: "Customer Management",
    icon: CustomerMgmtIcon,
    activeIcon: CustomerMgmtIcon,
    component: CustomersList,
    route: "/customer-list",
    sidebar: true,
    hasSubRoutes: true,
    permissions: [],
  },
  {
    title: "Customer List",
    component: CustomersList,
    route: "/customer-list",
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Customer List",
    component: AddCustomers,
    route: "/add-customer",
    sidebar: false,
    // subSidebar: true,
    permissions: [],
  },
  {
    title: "Customer List",
    component: AddCustomers,
    route: APP_ROUTES.CUSTOMER_EDIT,
    sidebar: false,
    // subSidebar: true,
    permissions: [],
  },

  {
    title: "Customer Management",
    component: CustomersView,
    route: APP_ROUTES.CUSTOMER_VIEW,
    sidebar: false,
    permissions: [],
  },

  {
    title: "Customer Management",
    component: AddGroup,
    route: "/add-group/:id",
    sidebar: false,
    permissions: [],
  },

  {
    title: "Groups",
    component: GroupList,
    route: "/group-list",
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Customer Management",
    component: GroupView,
    route: "/group-view/:id",
    sidebar: false,
    permissions: [],
  },
  {
    title: "Customer Management",
    component: AddGroup,
    route: "/add-group",
    sidebar: false,
    permissions: [],
  },

  {
    title: "Wallet",
    component: WalletList,
    route: APP_ROUTES.WALLET_LIST,
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Wallet",
    component: AddWallet,
    route: APP_ROUTES.WALLET_CREATE,
    sidebar: false,
    subSidebar: false,
    permissions: [],
  },
  {
    title: "Wallet",
    component: AddWallet,
    route: APP_ROUTES.WALLET_EDIT,
    sidebar: false,
    subSidebar: false,
    permissions: [],
  },
  {
    title: "Wallet",
    component: WalletView,
    route: APP_ROUTES.WALLET_VIEW,
    sidebar: false,
    subSidebar: false,
    permissions: [],
  },

  {
    title: "Rules",
    icon: RulesMgmtIcon,
    activeIcon: RulesMgmtIcon,
    component: RuleList,
    route: APP_ROUTES.RULE_LIST,
    sidebar: true,
    permissions: [],
  },
  {
    title: "Rules",
    icon: RulesMgmtIcon,
    activeIcon: RulesMgmtIcon,
    component: AddRule,
    route: APP_ROUTES.RULE_CREATE,
    sidebar: false,
    permissions: [],
  },

  {
    title: "Rules",
    icon: RulesMgmtIcon,
    activeIcon: RulesMgmtIcon,
    component: AddRule,
    route: APP_ROUTES.RULE_EDIT,
    sidebar: false,
    permissions: [],
  },

  {
    title: "Rules",
    component: ViewRule,
    route: APP_ROUTES.RULE_VIEW,
    sidebar: false,
    permissions: [],
  },
  {
    title: "Transactions",
    icon: transactionMgmtIcon,
    activeIcon: transactionMgmtIcon,
    component: TransactionList,
    route: APP_ROUTES.TRANSACTION_LIST,
    sidebar: true,
    permissions: [],
  },
  {
    title: "Transactions",
    component: TransactionView,
    route: APP_ROUTES.TRANSACTION_VIEW,
    sidebar: false,
    permissions: [],
  },
];

ROUTES[
  ROUTES.findIndex(
    (route) => route.title === "Customer Management" && route.sidebar
  )
]["subRoutes"] = [
  ROUTES[
    ROUTES.findIndex(
      (route) => route.title === "Customer List" && route.subSidebar
    )
  ],
  ROUTES[
    ROUTES.findIndex((route) => route.title === "Groups" && route.subSidebar)
  ],
  ROUTES[
    ROUTES.findIndex((route) => route.title === "Wallet" && route.subSidebar)
  ],
];

ROUTES[
  ROUTES.findIndex((route) => route.title === "Touch Point" && route.sidebar)
]["subRoutes"] = [
  ROUTES[
    ROUTES.findIndex(
      (route) => route.title === "Touchpoint List" && route.subSidebar
    )
  ],
  ROUTES[
    ROUTES.findIndex(
      (route) => route.title === "Touchpoint Groups" && route.subSidebar
    )
  ],
];

export default ROUTES;
