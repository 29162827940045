import { FETCH_RULE, RESET_RULE } from "../../constants/action.constants";

const initialState = {
  data: {},
};

//Controller for the storage.
const ruleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RULE:
      return { data: action.payload };
    case RESET_RULE:
      return initialState;
    default:
      return state;
  }
};

export default ruleReducer;
