import { NAVBAR_BRAND_ROUTES, TABLES_REF } from "./index";

export const getActiveRoute = (title, path) => {
  let flag = false;
  NAVBAR_BRAND_ROUTES[title].forEach((route) => {
    //retreive raw page link.
    //EXAMPLE: "/route/:id" converts into "route"
    if (route.includes(":")) {
      route = route.slice(0, route.indexOf(":"));
    }

    if (path.search(route) === 0) {
      flag = true;
    }
  });
  return flag;
};

export const getBrandName = (path) => {
  let keys = Object.keys(NAVBAR_BRAND_ROUTES);
  let brand = "";
  keys.forEach((key) => {
    NAVBAR_BRAND_ROUTES[key].forEach((route) => {
      if (path.search(route) === 0) {
        brand = key;
      }
    });
  });
  return brand;
};

export const getTableList = (refr) => {
  switch (refr) {
    case "order-list-table":
      return TABLES_REF.ORDER_LIST_TABLE;
    case "customer-list-table":
      return TABLES_REF.CUSTOMER_LIST_TABLE;
    case "group-list-table":
      return TABLES_REF.GROUP_LIST_TABLE;
    case "institution-list-table":
      return TABLES_REF.INSTITUTION_LIST_TABLE;
    case "channel-list-table":
      return TABLES_REF.CHANNEL_LIST_TABLE;
    case "touchpoint-list-table":
      return TABLES_REF.TOUCHPOINT_LIST_TABLE;
    case "touchpoint-group-list-table":
      return TABLES_REF.TOUCHPOINT_GROUP_LIST_TABLE;
    case "transaction-list-table":
      return TABLES_REF.TRANSACTION_LIST_TABLE;
    case "rule-list-table":
      return TABLES_REF.RULE_LIST_TABLE;
    case "wallet-list-table":
      return TABLES_REF.WALLET_LIST_TABLE;
    case "cart-order-list-table":
      return TABLES_REF.CART_ORDER_LIST_TABLE;
    case "order-refund-list-table":
      return TABLES_REF.ORDER_REFUND_LIST_TABLE;
    default:
      return [];
  }
};
