import { combineReducers } from "redux";
import { reducer as notifications } from "react-notification-system-redux";
import loginReducer from "./login.reducer";
import toastReducer from "./toast.reducer";
import permissionsReducer from "./permissions.reducer";
import cancelConfirmReducer from "./cancelConfirm.reducer";
import { LOG_OUT } from "../../constants/action.constants";
import customerListReducer from "./customerList.reducer";
import instanceListReducer from "./instanceList.reducer";
import channelListReducer from "./channelList.reducer";
import touchpointListReducer from "./touchpointList.reducer";
import groupListReducer from "./groupList.reducer";
import institutionListReducer from "./institutionList.reducer";
// import customerViewReducer from "./customerView.reducer";
import meReducer from "./me.reducer";
import orderViewReducer from "./orderView.reducer";
import customerViewReducer from "./customerView.reducer";
import groupViewReducer from "./groupView.reducer";
import institutionViewReducer from "./institutionView.reducer";

import ruleListReducer from "./ruleList.reducer";
import ruleReducer from "./ruleView.reducer";
import vmReducer from "./vendingMachineView.reducer";
import touchpointGroupListReducer from "./touchpointGroupList.reducer";
import touchpointGroupViewReducer from "./touchpointGroupView.reducer";
import tpGroupDropdownListReducer from "./touchpointGroupDropdownList.reducer";
import transactionListReducer from "./transactionList.reducer";
import transactionViewReducer from "./transactionView.reducer";
import walletListReducer from "./walletList.reducer";
import walletViewReducer from "./walletView.reducer";
const appReducer = combineReducers({
  notifications,
  loginReducer,
  // customerViewReducer,
  toastReducer,
  customerListReducer,
  permissionsReducer,
  cancelConfirmReducer,
  meReducer,
  ruleListReducer,
  ruleReducer,
  orderViewReducer,
  channelListReducer,
  instanceListReducer,
  touchpointListReducer,
  touchpointGroupListReducer,
  touchpointGroupViewReducer,
  vmReducer,
  groupListReducer,
  customerViewReducer,
  groupViewReducer,
  institutionListReducer,
  institutionViewReducer,
  tpGroupDropdownListReducer,
  transactionListReducer,
  transactionViewReducer,
  walletListReducer,
  walletViewReducer,
});

//Root reducer is used to revert back all reducers state to the initial state when user logs out.
const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    //All reducers return initial state when state not passed
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
